.info {
	display: flex;
	.children {
		flex-grow: 1;
	}
	.infoModal {
		padding: 0.5rem 0;
		padding-left: 1.25rem;
		.infoIcon {
			font-size: 2rem;
		}
	}
}
