.multi-progress-bar {
	margin: 20px 0;
	height: 30px;
	font-size: 1.3rem;
}

.table-progress-bar {
	height: 100%;
	font-size: 1.1rem;
}

.stat-label {
	font-size: 1.6rem;

	&__number {
		font-weight: bold;
	}
}

.stat-icon {
	font-size: 2rem;

	&--success {
		color: $green;
	}

	&--error {
		color: $red;
	}
}
