.statistics {
	&__main-grid {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		margin: 2rem;
		gap: 2rem;

		> * {
			background-color: $white;
		}
	}

	&__table {
		width: 100%;

		tr:nth-child(even) {
			background-color: $blue-pastel-dark;
		}
	}

	&__wrapper {
		padding: 2rem;
	}
}
