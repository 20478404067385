.button-reset {
	outline: none;
	width: 100%;
	border: none;
	background: none;
}

.readonly-input {
	input {
		cursor: pointer !important;
	}
}

.deeplink_tab_label {
	cursor: pointer;
	span {
		font-weight: 500;
	}
}

.deeplink_form {
	width: 75%;
	margin: 4rem auto;
}
