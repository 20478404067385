.subMenu,
.menuItem {
	transition: all 0.3s;
	&:hover {
		> .menuItemButtons {
			display: flex;
			align-items: center;
		}
	}
}

.menuItemButtons {
	display: none;
}
