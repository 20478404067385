.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
	background-color: #e6f7ff !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
	background-color: #f2f2f2 !important;
	font-weight: 600;
}

.ant-select-multiple .ant-select-selection-item-remove > .anticon {
	vertical-align: 0;
}

.ant-picker:hover,
.ant-picker-focused {
	input,
	div {
		cursor: pointer;
	}
}

.ant-select-dropdown {
	.rc-virtual-list-scrollbar {
		background-color: #f7f7f7 !important;
		width: 6px !important;
	}
	.rc-virtual-list-scrollbar-thumb {
		background-color: darken(#f7f7f7, 40) !important;
		border-radius: 2px !important;
	}
}
