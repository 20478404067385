.document_preview {
	width: 100%;
	padding: 5rem 0;
}

.document_preview_center {
	margin: 0 auto;
	width: 40%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.document_preview_title {
	margin: auto 0.5rem;
}

.document_image_preview {
	width: 100%;
	object-fit: contain;
	cursor: zoom-in !important;
	-webkit-box-shadow: 5px 7px 28px -7px rgba(66, 68, 90, 1);
	-moz-box-shadow: 5px 7px 28px -7px rgba(66, 68, 90, 1);
	box-shadow: 5px 7px 28px -7px rgba(66, 68, 90, 1);
}

.document_border {
	border: 3px #f3f3f4 solid;
}

.status_underline {
	text-decoration: underline;
	text-underline-offset: 0.6rem;
	text-decoration-thickness: 3px;
	text-decoration-color: $red;
}

.verified, .verified--text-color, .succeeded--text-color,  .active--text-color {
	text-decoration-color: $green;
	color: $green;
}

.unverified, .nothing_to_verify, .unverified--text-color, .canceled--text-color {
	text-decoration-color: $red;
	color: $red;
}

.being_verified, .being_verified--text-color, .pending--text-color {
	text-decoration-color: $navy;
	color: $navy;
}