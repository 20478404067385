.image-metadata-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 0.75em 0;
}

.image-metadata-info {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.image-metadata-size {
	font-size: 0.9em;
	font-weight: lighter;
	text-decoration: underline;
}

.image-metadata-requirements-list {
	text-decoration: none;
	list-style: none;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 0;
	margin: 0;
	li:after {
		content: '•';
		color: inherit;
		margin: 0 1.5rem;
	}
	li:last-child:after {
		content: none;
	}
}

.image-metadata-requirements-list-item {
	font-size: 1rem;
	@media (min-width: 1400px) {
		font-size: 1.2rem;
	}
	@media (min-width: 1600px) {
		font-size: 1.4rem;
	}
}

.valid {
	color: green;
}

.error {
	color: red;
}

.warning {
	color: orange;
}
