// *******------------ Spinner ------------********

.loginscreen.middle-box .sk-loading {
	width: 120px;
}

.ibox-content.sk-loading > .sk-spinner {
	width: 120px;
}

.ibox-content.sk-loading:after {
	display: none;
}

.loading {
	height: 100%;
	width: 100%;
} 

.main__loading {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
